import { ConfigProvider, theme } from "antd";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

// Layouts
import Layouts from "./components/common/Layout";

// Authentication
import LoginForm from "./pages/Login";
import { useAuth } from "./utils/store";

// Higher-Order Component for Layout Wrapping
const withLayout = (Component, additionalProps = {}) => {
  return (props) => (
    <Layouts>
      <Component {...props} {...additionalProps} />
    </Layouts>
  );
};

// Route Configurations
const routeConfigurations = [
  {
    category: "dashboard",
    routes: [
      { path: "/", component: React.lazy(() => import("./pages/Dashboard")) },
    ],
  },
  {
    category: "categories",
    routes: [
      {
        path: "/categories",
        component: React.lazy(() => import("./pages/Categories/All")),
      },
      {
        path: "/categories/add",
        component: React.lazy(() => import("./pages/Categories/Add")),
      },
      {
        path: "/categories/update/:id",
        component: React.lazy(() => import("./pages/Categories/Update")),
      },
    ],
  },
  {
    category: "subcategories",
    routes: [
      {
        path: "/subcategories/:cat",
        component: React.lazy(() =>
          import("./pages/Categories/sub-categoris/All")
        ),
      },
      {
        path: "/subcategories/:cat/add",
        component: React.lazy(() =>
          import("./pages/Categories/sub-categoris/Add")
        ),
      },
      {
        path: "/subcategories/:cat/update/:id",
        component: React.lazy(() =>
          import("./pages/Categories/sub-categoris/Update")
        ),
      },
    ],
  },
  {
    category: "users",
    routes: [
      {
        path: "/users",
        component: React.lazy(() => import("./pages/user/AllUser")),
        props: {},
      },
      {
        path: "/users/add",
        component: React.lazy(() => import("./pages/user/Add")),
        props: {},
      },
      {
        path: "/users/update/:id",
        component: React.lazy(() => import("./pages/user/Update")),
        props: {},
      },
      {
        path: "/vendors",
        component: React.lazy(() => import("./pages/user/AllUser")),
        props: { vendor: true },
      },
      {
        path: "/company",
        component: React.lazy(() => import("./pages/user/AllUser")),
        props: { vendor: true, company: true },
      },
      {
        path: "/vendors/add",
        component: React.lazy(() => import("./pages/user/Add")),
        props: { vendor: true },
      },
      {
        path: "/vendors/update/:id",
        component: React.lazy(() => import("./pages/user/Update")),
        props: { vendor: true },
      },
      {
        path: "/users/posts/:id",
        component: React.lazy(() => import("./pages/Posts")),
      },
      {
        path: "/users/collaboration/:id",
        component: React.lazy(() => import("./pages/user/Collaboration")),
      },
      {
        path: "/users/wallet/:id",
        component: React.lazy(() => import("./pages/user/Wallet")),
      },
      {
        path: "/users/notifications/:id",
        component: React.lazy(() => import("./pages/user/Notifications")),
      },
      {
        path: "/users/subscription/:id",
        component: React.lazy(() => import("./pages/user/SubscriptionHistory")),
      },
      {
        path: "/users/chatrooms/:id",
        component: React.lazy(() => import("./pages/user/Chatrooms")),
      },
      {
        path: "/users/purchase/:id",
        component: React.lazy(() =>
          import("./pages/user/TalentSeekerPurchase")
        ),
      },
    ],
  },
  {
    category: "subscriptions",
    routes: [
      {
        path: "/subscription",
        component: React.lazy(() => import("./pages/subscription/All")),
      },
      {
        path: "/subscription/add",
        component: React.lazy(() => import("./pages/subscription/Add")),
      },
      {
        path: "/subscription/update/:id",
        component: React.lazy(() => import("./pages/subscription/Update")),
      },
    ],
  },
  {
    category: "adverts",
    routes: [
      {
        path: "/advert",
        component: React.lazy(() => import("./pages/advert/AllAdvert")),
      },
      {
        path: "/advert/add",
        component: React.lazy(() => import("./pages/advert/Add")),
      },
      {
        path: "/advert/update/:id",
        component: React.lazy(() => import("./pages/advert/Update")),
      },
      {
        path: "/advert/collaboration/:id",
        component: React.lazy(() => import("./pages/advert/Collaboration")),
      },
      {
        path: "/advert/leaderboard/:id",
        component: React.lazy(() => import("./pages/advert/Leaderboard")),
      },
    ],
  },
  {
    category: "misc",
    routes: [
      {
        path: "/notifications",
        component: React.lazy(() => import("./pages/Notifications")),
      },
      {
        path: "/transactions",
        component: React.lazy(() => import("./pages/Transactions")),
      },
      {
        path: "/collaboration",
        component: React.lazy(() => import("./pages/Collaboration")),
      },
      {
        path: "/collaboration-approved",
        component: React.lazy(() => import("./pages/Collaboration")),
        props: { type: "Approved" },
      },
      {
        path: "/collaboration-rejected",
        component: React.lazy(() => import("./pages/Collaboration")),
        props: { type: "Rejected" },
      },
      {
        path: "/kyc",
        component: React.lazy(() => import("./pages/Kyc")),
      },
      {
        path: "/kyc-verified",
        component: React.lazy(() => import("./pages/KycVerified")),
      },
      {
        path: "/system-values",
        component: React.lazy(() => import("./pages/SystemValues")),
      },
      {
        path: "/posts",
        component: React.lazy(() => import("./pages/Posts")),
      },
      {
        path: "/admins",
        component: React.lazy(() => import("./pages/Admin")),
      },
      {
        path: "/login",
        component: React.lazy(() => import("./pages/Login")),
      },
    ],
  },
];

const App = () => {
  const user = useAuth((state) => state.user);
  const navigate = useNavigate();
  const antdTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
      colorPrimary: "#063035",
      borderRadius: 4,
    },
    components: {
      Table: {
        headerBg: "#f0f2f5",
        headerColor: "#262626",
        rowHoverBg: "#f5f5f5",
        stripedBg: "#fafafa",
        borderColor: "#f0f0f0",
        cellPaddingBlock: 12,
        cellPaddingInline: 16,
      },
      Button: {
        primaryShadow: "none",
      },
    },
  };
  React.useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <ConfigProvider
      theme={antdTheme}
      table={{
        size: "middle", // Default table size
        bordered: false,
        pagination: {
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        },
      }}
    >
      <React.Suspense
        fallback={
          <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="text-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7349 32.2913 88.0333 35.8758C89.0995 38.2635 91.6709 39.5819 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mt-4 text-gray-600 text-lg">
                Loading application...
              </p>
            </div>
          </div>
        }
      >
        <Routes>
          {routeConfigurations.flatMap(({ routes }) =>
            routes.map(({ path, component: Component, props = {} }) => (
              <Route
                key={path}
                path={path}
                element={
                  path !== "/login" && !path.includes("chatrooms") ? (
                    React.createElement(withLayout(Component), props)
                  ) : (
                    <Component />
                  )
                }
              />
            ))
          )}
          <Route path="/login" element={<LoginForm />} />
        </Routes>
      </React.Suspense>
    </ConfigProvider>
  );
};

export default App;
