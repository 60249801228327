"use client";
import clsx from "clsx";
import {
  Ampersand,
  ArrowRightLeft,
  Building,
  CalendarCheck,
  ChevronDown,
  FileText,
  HardDrive,
  LayoutDashboard,
  Megaphone,
  Network,
  ShieldCheck,
  ShieldHalf,
  Store,
  User2Icon,
} from "lucide-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const side = [
  {
    title: "Basic",
    links: [
      { title: "Dashboard", link: "/", icon: LayoutDashboard },
      { title: "System Values", link: "/system-values", icon: HardDrive },
      { title: "Admin", link: "/admins", icon: ShieldCheck },
      {
        title: "Subscription",
        link: "/subscription",
        icon: CalendarCheck,
      },
    ],
  },
  {
    title: "Category",
    links: [{ title: "Category", link: "/categories", icon: Network }],
  },
  {
    title: "Users",
    links: [
      { title: "Talent", link: "/users", icon: User2Icon },
      { title: "Talent Seekers", link: "/vendors", icon: Store },
      { title: "Company", link: "/company", icon: Building },
    ],
  },
  {
    title: "KYC",
    links: [
      { title: "KYC", link: "/kyc", icon: ShieldHalf },
      { title: "Verify KYC", link: "/kyc-verified", icon: ShieldHalf },
    ],
  },
  {
    title: "Posts",
    links: [{ title: "Posts", link: "/posts", icon: FileText }],
  },
  {
    title: "Earning",
    links: [
      { title: "Transactions", link: "/transactions", icon: ArrowRightLeft },
    ],
  },
  {
    title: "Advertisement",
    links: [
      { title: "Advertisement", link: "/advert", icon: Megaphone },
      { title: "Collaboration", link: "/collaboration", icon: Ampersand },
      {
        title: "Collaboration Approved",
        link: "/collaboration-approved",
        icon: Ampersand,
      },
      {
        title: "Collaboration Rejected",
        link: "/collaboration-rejected",
        icon: Ampersand,
      },
    ],
  },
];

const Sidebar = () => {
  return (
    <aside className="w-[18rem] p-6 sticky top-0 flex flex-col gap-2  bg-white shadow-sm  border-r">
      {side.map((s, index) => (
        <Nav key={index} link={s} index={0} />
      ))}
    </aside>
  );
};

export default Sidebar;

const Nav = ({ link, index }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  return (
    <div className="flex flex-col gap-3">
      {index === 0 ? (
        <div>
          <p className="font-bold my-3 uppercase text-xs  text-gray-500 ">
            {link?.title}
          </p>
          {link?.links?.map((l, i) => (
            <Nav key={i} link={l} index={index + 1} />
          ))}
        </div>
      ) : link?.links ? (
        <div key={link.title}>
          <button
            onClick={() => setOpen(!open)}
            className="flex gap-4 rounded-lg text-gray-600  items-center justify-between px-4 py-2 w-full hover:bg-gray-100 transition-colors "
          >
            <div className="flex gap-2 font-semibold text-sm  items-center">
              <link.icon size={16} />
              {link.title}
            </div>
            <ChevronDown size={16} />
          </button>
          {open && (
            <div>
              {link?.links?.map((l, i) => (
                <Nav key={i} link={l} index={index + 1} />
              ))}
            </div>
          )}
        </div>
      ) : (
        <Link
          to={link.link}
          key={link.title}
          className={clsx(
            "flex gap-2 font-semibold text-sm px-4 hover:text-black rounded-lg py-2 text-gray-600 items-center hover:bg-gray-100 transition-colors ",
            location.pathname === link.link && "bg-primary text-white"
          )}
        >
          {link.icon && <link.icon size={16} />}
          {link.title}
        </Link>
      )}
    </div>
  );
};
