import { Button, Image, Layout } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/store";
import Sidebar from "./Sidebar";
const { Header, Content, Sider } = Layout;

const Layouts = ({ children }) => {
  const logout = useAuth((state) => state.logout);
  const user = useAuth((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user?.email) {
      navigate("/login");
    }
  }, [user, navigate, location]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header className="border-b h-auto px-4  py-3 bg-white shadow-sm">
        <div className="flex flex-row px-6 justify-between items-center">
          <Image src={"/logo.png"} width={100} preview={false} />
          <div className="flex flex-row gap-2 items-center">
            <a
              href="https://console.firebase.google.com/project/tangent-website-479e6/analytics/app/web:YjU5ODBhNzUtNDA1OS00MTlmLTk1OTAtMjM4ZmE3YTY1YjMx/overview"
              target="_blank"
              rel="noreferrer"
              className="flex items-center"
            >
              <Button type="primary">Analytics</Button>
            </a>
            <Button type="primary" danger onClick={() => logout()}>
              Logout
            </Button>
          </div>
        </div>
      </Header>

      <Layout>
        <Sider
          width={288}
          breakpoint="lg"
          collapsedWidth="0"
          className="!bg-white border-r"
        >
          <Sidebar />
        </Sider>
        <Layout>
          <Content
            style={{
              margin: 0,
              minHeight: 280,
            }}
          >
            <div className="p-4 sm:p-10">{children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default Layouts;
